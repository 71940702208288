import React from 'react';
import placeholderImage from './images/hedlyroomsflyer1.png';

function App() {
  return (
    <div className="App">
      <center><img src={placeholderImage} alt="placeholderImage" /></center>
    </div>
  );
}

export default App;
